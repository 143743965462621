exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-blog-js": () => import("./../../../src/pages/blog.js" /* webpackChunkName: "component---src-pages-blog-js" */),
  "component---src-pages-design-consultation-js": () => import("./../../../src/pages/design-consultation.js" /* webpackChunkName: "component---src-pages-design-consultation-js" */),
  "component---src-pages-expert-help-js": () => import("./../../../src/pages/expert-help.js" /* webpackChunkName: "component---src-pages-expert-help-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-inspiration-js": () => import("./../../../src/pages/inspiration.js" /* webpackChunkName: "component---src-pages-inspiration-js" */),
  "component---src-pages-promotions-js": () => import("./../../../src/pages/promotions.js" /* webpackChunkName: "component---src-pages-promotions-js" */),
  "component---src-templates-blog-post-js": () => import("./../../../src/templates/blogPost.js" /* webpackChunkName: "component---src-templates-blog-post-js" */),
  "component---src-templates-inspiration-post-js": () => import("./../../../src/templates/inspirationPost.js" /* webpackChunkName: "component---src-templates-inspiration-post-js" */),
  "component---src-templates-page-js": () => import("./../../../src/templates/page.js" /* webpackChunkName: "component---src-templates-page-js" */),
  "component---src-templates-product-js": () => import("./../../../src/templates/product.js" /* webpackChunkName: "component---src-templates-product-js" */)
}

