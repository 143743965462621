import * as React from "react"
import 'normalize.css'
import { createGlobalStyle } from "styled-components"

import Header from './header'
import Footer from './footer'

const GlobalStyle = createGlobalStyle`
  :root {
    --body-font: "Arimo", sans-serif;
    --heading-font: "PT Serif", serif;

    --font-regular: 400;
    --font-medium: 500;
    --font-semi-bold: 600;
    --font-bold: 700;

    --black: #000;
    --charcoal: #2e2e2e;
    --white: #fff;
    --white-trans: rgba(255 255 255 / 0.85);
    --gray: #a9a9a9;
    --light-gray: #e0e0e0;
    --dark-gray: #5b5b5b;
    --red: #da0000;
  }

  *, *:before, *:after {
    box-sizing: border-box;
  }

  html {
    box-sizing: border-box;
  }

  body {
    font-family: var(--body-font);
    font-weight: var(--font-regular);
    color: var(--charcoal);
    font-size: 19px;
    line-height: 1.2;
    position: relative;
    overflow: visible;

    @media(min-width: 768px) {
      font-size: 20px;
    }

    .container {
      margin: 0 auto;
      max-width: 1200px;
      position: relative;
      padding-left: 15px;
      padding-right: 15px;
      box-sizing: border-box;

      @media(min-width: 1240px) {
        padding-left: 0;
        padding-right: 0;
      }
    }

    .row-two-col {
      display: grid;
      grid-template-columns: 1fr;
      column-gap: 50px;
      row-gap: 30px;

      @media(min-width: 992px) {
        grid-template-columns: 1fr 1fr;
        column-gap: 60px;
      }
    }

    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
      font-family: var(--heading-font);
      color: var(--charcoal);
      line-height: 1.1;
      margin-top: 0;
      margin-bottom: 25px;
      font-weight: var(--font-regular);
    }

    h1 {
      font-size: 43px;
  
      @media(min-width: 768px) {
        font-size: 50px;
      }
    }

    h2 {
      font-size: 36px;
  
      @media(min-width: 768px) {
        font-size: 40px;
      }
    }

    h3 {
      font-size: 30px;
  
      @media(min-width: 768px) {
        font-size: 35px;
      }
    }

    h4 {
      font-size: 26px;
  
      @media(min-width: 768px) {
        font-size: 30px;
      }
    }

    h5 {
      font-size: 25px;
    }

    h6 {
      font-size: 23px;
    }

    p {
      margin: 0 0 30px 0;
      line-height: 1.2;
      font-size: 18px;
    }

    a {
      color: var(--red);
      text-decoration: underline;
    }

    .btn {
      padding: 12px 25px;
      font-weight: var(--font-bold);
      font-size: 21px;
      line-height: 1;
      text-align: center;
      color: var(--charcoal);
      display: inline-block;
      text-decoration: none;
      background: var(--white);
      border: 1px solid var(--red);
      border-radius: 0;

      &:hover {
        background: var(--charcoal);
        color: var(--white);
        border: 1px solid var(--charcoal);
        cursor: pointer;
      }
    }

    .btn-black {
      color: var(--white);
      background: var(--charcoal);
      border: 1px solid var(--charcoal);

      &:hover {
        color: var(--charcoal);
        background: var(--white);
        border: 1px solid var(--charcoal);
      }
    }

    .btn-white {
      color: var(--charcoal);
      background: var(--white);
      border: 1px solid var(--charcoal);

      &:hover {
        color: var(--white);
        background: var(--charcoal);
        border: 1px solid var(--charcoal);
      }
    }

    .btn-red {
      color: var(--white);
      background: var(--red);
      border: 1px solid var(--red);

      &:hover {
        color: var(--charcoal);
        background: var(--white);
        border: 1px solid var(--red);
      }
    }

    .btn-outline {
      color: var(--charcoal);
      background: var(--white);
      border: 1px solid var(--red);

      &:hover {
        color: var(--white);
        background: var(--red);
        border: 1px solid var(--red);
      }
    }

    .btn-small {
      padding: 7px 15px;
    }
  }
`

const Layout = ({ children }) => {

  return (
    <>
      <GlobalStyle />
      <Header />
      <main>{children}</main>
      <Footer />
    </>
  )
}

export default Layout
