import React, { useState } from 'react'
import styled from "styled-components"
import { useStaticQuery, graphql, Link } from 'gatsby'

import FacebookLogoMobile from '../images/logo-facebook.svg'
import InstagramLogoMobile from '../images/logo-instagram.svg'
import PinterestLogoMobile from '../images/logo-pinterest.svg'
import MobileNav from '../images/icon-mobile-nav.svg'
import MobileLogoWhite from '../images/interior-details-header-logo-white.svg'
import MobileClose from '../images/icon-mobile-close.svg'

const HeaderStyle = styled.header`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  position: fixed;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  z-index: 2;
  padding: 10px 15px;

  @media(min-width: 1220px) {
    padding: 10px 40px;
  }

  &.sticky-time {
    z-index: 1000;
    background-color: var(--charcoal);
    transition: 0.25s all;
    box-shadow: 0 1px 7px 0 rgba(0, 0, 0, 0.50);
  }
`

const Branding = styled.div`
  a {
    text-decoration: none;
    display: block;
    line-height: 1;
    height: 60px;

    img {
      width: 220px;

      @media(min-width: 500px) {
        width: 243px;
      }
    }

    
  }
`

const Navigation = styled.div`
  display: flex;  
  align-items: center;
  justify-content: flex-end;
  gap: 40px;

  nav {
    display: none;

    @media(min-width: 1220px) {
      display: flex;
    }
  }
`

const NavBar = styled.nav`
  list-style: none;
  margin: 0;
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 25px;

  .nav-consultation-btn {
    padding: 5px 10px;
    font-weight: var(--font-regular);
    font-size: 19px;
    line-height: 1;
    text-align: center;
    color: var(--charcoal);
    display: inline-block;
    text-decoration: none;
    background: var(--white-trans);
    border: 1px solid var(--red);
    border-radius: 0;
  }

  .nav-phone {
    color: white;
    font-size: 19px;
    font-weight: var(--font-regular);
    text-decoration: none;
    text-transform: capitalize;
    text-shadow: 0 0 1px rgba(0,0,0,0.40);

    &:hover {
      text-decoration: underline;
      text-decoration-color: var(--red);
      text-underline-offset: 3px;
      color: var(--white);
    }
  }
`

const NavDropdown = styled.li`
  position: relative;
  display: inline-block;
  padding-top: 10px;
  padding-bottom: 10px;

  &:hover .sub-nav {
    opacity: 1;
    transition: top 0s linear 0s, opacity .3s ease .01s;
    top: 18px;
  }

  > a {
    color: white;
    font-size: 19px;
    font-weight: var(--font-regular);
    text-decoration: none;
    text-transform: capitalize;
    text-shadow: 0 0 1px rgba(0,0,0,0.40);

    &:hover {
      text-decoration: underline;
      text-decoration-color: var(--red);
      text-underline-offset: 3px;
      color: var(--white);
    }
  }
`

const SubNav = styled.ul`
  opacity: 0;
  transition: opacity .3s ease 0s, transform 0s linear .9s;
  position: absolute;
  top: -1000px;
  background-color: rgba(255 255 255 / 0.9);
  min-width: 220px;
  padding: 12px;
  z-index: 1;
  list-style: none;

  li {
    margin-bottom: 10px;

    &:last-child {
      margin-bottom: 0;
    }
  }

  a {
    color: var(--charcoal);
    font-size: 18px;
    font-weight: var(--font-medium);
    text-decoration: none;
    line-height: 1;

    &:hover {
      text-decoration: underline;
      text-decoration-color: var(--red);
      text-underline-offset: 3px;
    }
  }
`

const MobileNavigation = styled.div`
  display: block;

  @media(min-width: 1220px) {
    display: none;
  }
`

const MobileSlideIn = styled.div`
  position: absolute;
  top: 0;
  right: -320px;
  width: 320px;
  height: 100%;
  min-height: 100vh;
  z-index: 999;
  background-color: var(--charcoal);
  padding: 10px 25px 25px;
  overflow: auto;
  box-sizing: border-box;
  display: none;
  -ms-overflow-style: none;
  scrollbar-width: none;

  &::-webkit-scrollbar {
    display: none;
  }

  &.active {
    display: block;
    right: 0;
  }

  .mobile-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-top: 15px;
  }
`

const MobileNavBar = styled.ul`
  list-style: none;
  margin: 0;
  padding: 30px 0;
  display: block;

  .nav-phone {
    color: white;
    font-size: 18px;
    font-weight: var(--font-regular);
    text-decoration: none;
    text-transform: capitalize;
    text-shadow: 0 0 1px rgba(0,0,0,0.40);

    &:hover {
      text-decoration: underline;
      text-decoration-color: var(--red);
      text-underline-offset: 3px;
      color: var(--white);
    }
  }

  .nav-consultation-mob {
    padding-top: 10px;
    padding-bottom: 10px;

    > a {
      color: white;
      font-size: 22px;
      
      text-decoration: none;
      text-transform: uppercase;

      &:hover {
        text-decoration: underline;
        color: gray;
      }
    }
  }
`

const MobileNavDropdown = styled.li`
  display: block;
  padding-top: 10px;
  padding-bottom: 10px;

  > a {
    color: white;
    font-size: 22px;
    
    text-decoration: none;
    text-transform: uppercase;

    &:hover {
      text-decoration: underline;
      color: gray;
    }
  }
`

const MobileSubNav = styled.ul`
  opacity: 1;
  list-style: none;
  padding-left: 10px;

  li {
    margin-bottom: 12px;

    &:first-child {
      padding-top: 20px;
    }

    &:last-child {
      margin-bottom: 0;
    }
  }

  a {
    color: white;
    font-size: 19px;
    font-weight: regular;
    text-decoration: none;
    line-height: 1;

    &:hover {
      text-decoration: underline;
    }
  }
`

const MobileSocialButtons = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 15px;

  a {
    line-height: 1;
  }
`

export function Header() {
  const [isActive, setActive] = useState(false)

  const toggleClass = () => {
    setActive(!isActive)
  }

  // add sticky class on scroll
  if (typeof window !== 'undefined') {
    window.onscroll = function() {
      let body = document.body; //IE 'quirks'
      let doc = document.documentElement; //IE with doctype
      doc = (doc.clientHeight) ? doc : body;
      let distance = (window.pageYOffset || doc.scrollTop) - (doc.clientTop || 0);

      if (distance === 0) {
        document.querySelector('header').classList.remove('sticky-time');
        console.log("doc.scrollTop == 0");
      } else {
        document.querySelector('header').classList.add('sticky-time');
      }
    }
  }

  const data = useStaticQuery(graphql`
    {
      HeaderData: wpMenu(locations: {eq: MENU_1}) {
        menuItems {
          nodes {
            label
            uri
            id
            parentId
            cssClasses
            childItems {
              nodes {
                label
                uri
                id
              }
            }
          }
        }
      }
      SocialData: allWp {
        nodes {
          acfOptionsOfficeSettings {
            socialSettings {
              facebookUrl
              instagramUrl
              pinterestUrl
            }
          }
        }
      }
    }
  `)

  const menuData = data.HeaderData.menuItems
  const socialItems = data.SocialData

  return (
    <HeaderStyle>
      <Branding>
        <Link to="/">
          <img src={MobileLogoWhite} width="243" height="60" alt="Interior Details Logo" />
        </Link>
      </Branding>
      <Navigation>
        <NavBar>
          {menuData && menuData.nodes.map((menuItem) => (
            <>
            {!menuItem.parentId &&
            <NavDropdown key={menuItem.id}>
              <Link to={menuItem.uri}>{menuItem.label}</Link>
              {menuItem.cssClasses.length === 1 &&
                <SubNav className="sub-nav">
                {menuItem.childItems && menuItem.childItems.nodes.map((childItem) => (
                  <li key={childItem.id}>
                    <Link to={childItem.uri}>{childItem.label}</Link>
                  </li>
                ))}
                </SubNav> 
              }
            </NavDropdown>
            }
            </>
          ))}
          <li><a href="/design-consultation/" className="nav-consultation-btn">Consultation</a></li>
          <li><a className="nav-phone" href="tel:781-792-0004" target="_blank" rel="noreferrer">781-792-0004</a></li>
        </NavBar>
        <MobileNavigation>
          <div role="button" className={isActive ? "active" : ""} onClick={toggleClass} onKeyDown={toggleClass} tabIndex={0}>
            <img src={MobileNav} width="30" height="12" alt="Mobile Menu Nav" />
          </div>
        </MobileNavigation>
      </Navigation>
      <MobileSlideIn className={isActive ? "active" : ""}>
        <div className="slide-in-content">
          <div className="mobile-header">
            <Link to="/">
              <img src={MobileLogoWhite} width="200" height="49" alt="Interior Details Logo" />
            </Link>
            <div role="button" className={isActive ? "active" : ""} onClick={toggleClass} onKeyDown={toggleClass} tabIndex={0}>
              <img src={MobileClose} width="24" height="24" alt="Mobile Menu Nav" />
            </div>
          </div>
          <div className="mobile-nav">
            <MobileNavBar>
              {menuData.nodes.map((menuItem) => (
                <>
                {!menuItem.parentId &&
                <MobileNavDropdown key={menuItem.id}>
                  <Link to={menuItem.uri} className={isActive ? "active" : ""} onClick={toggleClass} onKeyDown={toggleClass}>{menuItem.label}</Link>
                  {menuItem.childItems &&
                    <MobileSubNav className="sub-nav">
                    {menuItem.childItems && menuItem.childItems.nodes.map((childItem) => (
                      <li key={childItem.id}>
                        <Link to={childItem.uri} className={isActive ? "active" : ""} onClick={toggleClass} onKeyDown={toggleClass}>{childItem.label}</Link>
                      </li>
                    ))}
                    </MobileSubNav> 
                  }
                </MobileNavDropdown>
                }
                </>
              ))}
              <li className="nav-consultation-mob"><Link to="/design-consultation">Consultation</Link></li>
              <li><a className="nav-phone" href="tel:781-792-0004" target="_blank" rel="noreferrer">781-792-0004</a></li>
            </MobileNavBar>
          </div>
          {socialItems.nodes.map((node, index) => (
            <MobileSocialButtons key={index}>
              <a href={node.acfOptionsOfficeSettings.socialSettings.facebookUrl} target="_blank" rel="noreferrer">
                <img src={FacebookLogoMobile} width="30" height="30" alt="Facebook Logo" />
              </a>
              <a href={node.acfOptionsOfficeSettings.socialSettings.instagramUrl} target="_blank" rel="noreferrer">
                <img src={InstagramLogoMobile} width="30" height="30" alt="Instagram Logo" />
              </a>
              
              <a href={node.acfOptionsOfficeSettings.socialSettings.pinterestUrl} target="_blank" rel="noreferrer">
                <img src={PinterestLogoMobile} width="30" height="30" alt="Pinterest Logo" />
              </a>
            </MobileSocialButtons>
          ))}
        </div>
      </MobileSlideIn>
    </HeaderStyle>
  )
}

export default Header
