import React, { useState } from "react"
import styled from "styled-components"
import { useStaticQuery, graphql, Link } from 'gatsby'
import addToMailchimp from "gatsby-plugin-mailchimp"

import FacebookLogo from '../images/logo-facebook.svg'
import InstagramLogo from '../images/logo-instagram.svg'
import PinterestLogo from '../images/logo-pinterest.svg'

const FooterStyle = styled.footer`
  padding: 35px 20px 25px;
  max-width: 1200px;
  margin: 0 auto;
`

const FooterTopBar = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  text-align: center;
  row-gap: 20px;
  margin-bottom: 40px;

  @media(min-width: 992px) {
    justify-content: flex-start;
    flex-wrap: nowrap;
    column-gap: 50px;
  }

  p {
    width: auto;
    font-size: 20px;
    line-height: 1.2;
    margin-bottom: 0;
  }
`

const FooterCols = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 40px;
  margin-bottom: 30px;
  text-align: center;

  @media(min-width: 992px) {
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
    text-align: left;
  }

  .footer-col {
    h4 {
      font-size: 24px;
      line-height: 1;
      font-weight: var(--font-bold);
      margin-bottom: 12px;
    }

    p {
      margin-bottom: 10px;
    }

    .btn {
      margin-bottom: 20px;
      border-color: var(--red);
    }

    .footer-phone {
      color: var(--charcoal);
      font-size: 18px;
    }

    .footer-hours {
      font-size: 18px;
      line-height: 1.4;
    }

    .footer-social {
      display: flex;
      align-items: center;
      gap: 10px;
    }
  }
`

const NewsletterWrapper = styled.form`
    
`

const DescriptionWrapper = styled.div`
    color: var(--red);
`

const InputWrapper = styled.div`
    display: flex;
    flex-direction: column;

    label {
      font-size: 18px;
      margin-bottom: 5px;
    }
`

const Input = styled.input`
    margin-bottom: 10px;
    font-size: 16px;
    height: 35px;
    width: 250px;
    padding: 12px 10px 7px;

    :invalid {
        border: 1px solid red;
    }
`

const ButtonWrapper = styled.div`
    margin-top: 5px;

    button {
      margin-bottom: 0;
    }
`

const Copyright = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  text-align: center;

  @media(min-width: 860px) {
    justify-content: space-between;
  }

  p {
    font-size: 15px;
    margin-bottom: 0;
  }

  a {
    color: var(--red);
  }
`

export function Footer() {
  const data = useStaticQuery(graphql`
    {
      FooterData: allWp {
        nodes {
          acfOptionsOfficeSettings {
            footerSettings {
              footerLogo {
                altText
                sourceUrl
              }
              footerParagraph
              copyright
            }
            officeSettings {
              officeAddress
              officeCity
              officeState
              officeZipcode
              officeGoogleMapsUrl
              officePhone
              officeHoursMondayFriday
              officeHoursSaturday
              officeHoursSunday
            }
            socialSettings {
              facebookUrl
              instagramUrl
              pinterestUrl
            }
          }
        }
      }
    }
  `)

  const [email, setEmail] = useState("")
  const [fname, setFName] = useState("")
  const [lname, setLName] = useState("")
  const [submitted, setSubmitted] = useState(false)   

  function errorHandling(data) {
    // your error handling
  }

  const handleSubmit = () => {
    addToMailchimp(email, {
      FNAME: fname,
      LNAME: lname,
    }).then((data) => {

      if (data.result === "error") {
        errorHandling(data)
      } else {
        setSubmitted(true)
      }
    })
  }

  return (
    <>
      {data.FooterData.nodes.map((node, index) => (
        <FooterStyle key={index}>
          <FooterTopBar>
            <img src={node.acfOptionsOfficeSettings.footerSettings.footerLogo.sourceUrl} alt={node.acfOptionsOfficeSettings.footerSettings.footerLogo.altText} />
            <p>{node.acfOptionsOfficeSettings.footerSettings.footerParagraph}</p>
          </FooterTopBar>
          <FooterCols>
            <div className="footer-col">
              <h4>Address</h4>
              <p>
                {node.acfOptionsOfficeSettings.officeSettings.officeAddress}<br />
                {node.acfOptionsOfficeSettings.officeSettings.officeCity}, {node.acfOptionsOfficeSettings.officeSettings.officeState} {node.acfOptionsOfficeSettings.officeSettings.officeZipcode}
              </p>
              <a href={node.acfOptionsOfficeSettings.officeSettings.officeGoogleMapsUrl} className="btn btn-white" target="_blank" rel="noreferrer">Get Directions</a>
              <h4>Phone</h4>
              <a href={`tel:${node.acfOptionsOfficeSettings.officeSettings.officePhone}`} className="footer-phone" target="_blank" rel="noreferrer">{node.acfOptionsOfficeSettings.officeSettings.officePhone}</a>
            </div>

            <div className="footer-col">
              <h4>Hours</h4>
              <p className="footer-hours">
                <b>Monday-Friday:</b> {node.acfOptionsOfficeSettings.officeSettings.officeHoursMondayFriday}<br />
                <b>Saturday:</b> {node.acfOptionsOfficeSettings.officeSettings.officeHoursSaturday}<br />
                <b>Sunday:</b> {node.acfOptionsOfficeSettings.officeSettings.officeHoursSunday}
              </p>
            </div>

            <div className="footer-col">
              <h4>Social</h4>
              <div className="footer-social">
                <a href={node.acfOptionsOfficeSettings.socialSettings.facebookUrl} target="_blank" rel="noreferrer">
                  <img src={FacebookLogo} width="30" height="30" alt="Facebook Logo" />
                </a>
                <a href={node.acfOptionsOfficeSettings.socialSettings.instagramUrl} target="_blank" rel="noreferrer">
                  <img src={InstagramLogo} width="30" height="30" alt="Facebook Logo" />
                </a>
                <a href={node.acfOptionsOfficeSettings.socialSettings.pinterestUrl} target="_blank" rel="noreferrer">
                  <img src={PinterestLogo} width="30" height="30" alt="Pinterest Logo" />
                </a>
              </div>
            </div>

            <div className="footer-col">
              <h4>Join Email</h4>

              {submitted ? (
                <NewsletterWrapper>
                  <DescriptionWrapper>
                    <p>
                      Successfully subscribed!
                    </p>
                  </DescriptionWrapper>
                </NewsletterWrapper>
              ) : (
                <NewsletterWrapper>
                  <InputWrapper>
                    <label htmlFor="fname">First Name</label>
                    <Input
                      type="text"
                      name="fname"
                      id="fname"
                      label="fname-input"
                      onChange={(e) => setFName(e.target.value)}
                    />
                    <label htmlFor="lname">Last Name</label>
                    <Input
                      type="text"
                      name="lname"
                      id="lname"
                      label="lname-input"
                      onChange={(e) => setLName(e.target.value)}
                    />
                    <label htmlFor="email">Email</label>
                    <Input
                      type="email"
                      name="email"
                      id="mail"
                      label="email-input"
                      onChange={(e) => setEmail(e.target.value)}
                    />
                  </InputWrapper>
                  <ButtonWrapper>
                    <button
                      type="button"
                      aria-label="Join"
                      onClick={() => handleSubmit()}
                      className="btn btn-outline btn-small"
                    >
                      Join
                    </button>
                  </ButtonWrapper>
                </NewsletterWrapper>
              )}
            </div>
          </FooterCols>
          <Copyright>
            <p>Copyright &copy; {new Date().getFullYear()} - {node.acfOptionsOfficeSettings.footerSettings.copyright}</p>
            <p><Link to='/privacy-policy/'>Privacy Policy</Link> - Designed & Developed by <a href="https://punchbugmarketing.com" target="_blank" rel="noreferrer">Punch Bug Marketing</a></p>
          </Copyright>
        </FooterStyle>
      ))}
    </>
)}

export default Footer
