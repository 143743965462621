import React from 'react'
import "@fontsource/pt-serif/400.css"
import "@fontsource/arimo/400.css"
import "@fontsource/arimo/500.css"
import "@fontsource/arimo/700.css"

const Layout = require("./src/components/layout").default

export function wrapPageElement({ element, props }) {

  return (
    <>
      <Layout {...props}>{element}</Layout>
    </>
  )
}
